import React, { useState } from "react";
import TextArea from "../textarea/TextArea";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import "./Contact.css";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

function ErrorText({ children }) {
  return <span className="error-text">* {children}</span>;
}

const FORM_INITIAL_DATA = { name: "", email: "", message: "" };

function Contact() {
  const [formData, setFormData] = useState(FORM_INITIAL_DATA);
  const [formErrors, setFormErrors] = useState({});

  const reset = () => {
    setFormData(FORM_INITIAL_DATA);
    setFormErrors({});
  };

  const validate = () => {
    let errors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (isEmpty(value)) {
        errors = { ...errors, [key]: `${capitalize(key)} cannot be empty` };
      }
    });

    if (!errors.email) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        errors = { ...errors, email: "Email format is invalid" };
      }
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors({});
    const errors = validate();
    if (!isEmpty(errors)) {
      setFormErrors(errors);
      return;
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData }),
    })
      .then(() => {})
      .catch(() => {})
      .finally(reset);
  };

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <form className="contact" onSubmit={handleSubmit} noValidate>
      <label htmlFor="name"> Name:</label>
      <input
        className={formErrors.name ? "error" : null}
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      {formErrors.name && <ErrorText>{formErrors.name}</ErrorText>}

      <label htmlFor="email"> Email:</label>
      <input
        className={formErrors.email ? "error" : null}
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      {formErrors.email && <ErrorText>{formErrors.email}</ErrorText>}

      <label htmlFor="message">Message:</label>
      <TextArea
        className={formErrors.message ? "error" : null}
        id="message"
        name="message"
        value={formData.message}
        onChange={handleChange}
      />
      {formErrors.message && <ErrorText>{formErrors.message}</ErrorText>}

      <button type="submit">Send</button>
    </form>
  );
}

export default Contact;
