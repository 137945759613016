import React, { useState } from "react";
import "./TextArea.css";

const MIN_ROWS = 5;

function TextArea({ onChange, ...rest }) {
  const [rows, setRows] = useState(MIN_ROWS);

  const handleChange = (e) => {
    const rowCount = e.target.value.split("\n").length;
    setRows(rowCount < MIN_ROWS ? MIN_ROWS : rowCount + 1);
    onChange(e);
  };

  return <textarea rows={rows} onChange={handleChange} {...rest} />;
}

export default TextArea;
