import React from "react";
import Header from "./components/header/Header";
import Social from "./components/social/Social";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import "./App.css";
import "./env";

function App() {
  return (
    <div className="app">
      <div className="content">
        <Header />
        <Social />
        {window.contactform && <Contact />}
      </div>
      <Footer />
    </div>
  );
}

export default App;
