import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <small>
        Copyright © {new Date().getFullYear()} Jacques du Toit. All rights
        reserved.
      </small>
    </footer>
  );
}

export default Footer;
