import React from "react";
import "typeface-playfair-display";
import "./Header.css";

function Header() {
  return (
    <header className="header" data-testid="header component">
      <h1>
        Hi <br />
        I'm Jacques <br />a <span className="playfair">Web Developer</span>
        <span className="red">.</span>
      </h1>
    </header>
  );
}

export default Header;
