import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import "./Social.css";

function Social() {
  return (
    <div className="social">
      <a
        href="https://www.linkedin.com/in/jacques-du-toit-427b09109/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin />
      </a>
      <a
        href="https://github.com/Milkjake"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaGithub />
      </a>
    </div>
  );
}

export default Social;
